<template>
  <section class="page-container">
    <div ref="toolbar" class="toolbar">
      <el-form :inline="true" :model="filters">
        <el-form-item>
          <el-input v-model="filters.term" placeholder="名称" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="list"> 查询 </el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleAdd"> 新增 </el-button>
        </el-form-item>
      </el-form>
    </div>

    <div ref="mainContent" :style="{ height: mainHeight }">
      <el-table
        v-loading="listLoading"
        height="100%"
        :data="results"
        highlight-current-row
        stripe
        border
      >
        <el-table-column prop="id" label="编号" width="60" />
        <el-table-column prop="term" label="名称" width="320" />
        <el-table-column prop="definition" label="定义" />
        <el-table-column prop="grouping" label="分组" />
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            {{ scope.row.status === 0 ? "启用" : "禁用" }}
          </template>
        </el-table-column>

        <el-table-column prop="createdAt" label="创建时间">
          <template slot-scope="scope">
            {{ scope.row.createdAt | formatDate }}
          </template>
        </el-table-column>

        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button-group>
              <el-tooltip
                class="item"
                effect="dark"
                content="编辑"
                placement="left-start"
              >
                <el-button
                  size="mini"
                  icon="el-icon-edit"
                  @click="handleEdit(scope.$index, scope.row)"
                >
                </el-button>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="删除"
                placement="right-start"
              >
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-delete"
                  @click="handleDelete(scope.$index, scope.row)"
                >
                </el-button>
              </el-tooltip>
            </el-button-group>
          </template>
        </el-table-column>
        <template #empty>
          <el-empty />
        </template>
      </el-table>
    </div>

    <div ref="pagebar" class="pagebar">
      <el-pagination
        :page-sizes="[20, 50, 100, 300]"
        :current-page="page.page"
        :page-size="page.limit"
        :total="page.total"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handlePageChange"
        @size-change="handleLimitChange"
      />
    </div>

    <el-dialog
      :visible.sync="addFormVisible"
      :close-on-click-modal="false"
      title="新增"
    >
      <el-form ref="addForm" :model="addForm" label-width="80px">
        <el-form-item label="名称">
          <el-input v-model="addForm.term" />
        </el-form-item>
        <el-form-item label="定义" prop="definition">
          <el-input v-model="addForm.definition" />
        </el-form-item>
        <el-form-item label="分组" prop="grouping">
          <el-input v-model="addForm.grouping" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="addFormVisible = false"> 取消 </el-button>
        <el-button
          :loading="addLoading"
          type="primary"
          @click.native="addSubmit"
        >
          提交
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="editFormVisible"
      :close-on-click-modal="false"
      title="编辑"
    >
      <el-form ref="editForm" :model="editForm" label-width="80px">
        <el-input v-model="editForm.id" type="hidden" />
        <el-form-item label="名称">
          <el-input v-model="editForm.term" />
        </el-form-item>
        <el-form-item label="定义" prop="definition">
          <el-input v-model="editForm.definition" />
        </el-form-item>
        <el-form-item label="分组" prop="grouping">
          <el-input v-model="editForm.grouping" />
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="editForm.status" placeholder="请选择状态">
            <el-option :value="0" label="启用" />
            <el-option :value="1" label="禁用" />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="editFormVisible = false"> 取消 </el-button>
        <el-button
          :loading="editLoading"
          type="primary"
          @click.native="editSubmit"
        >
          提交
        </el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import mainHeight from "@/utils/mainHeight";
export default {
  name: "Tags",
  data() {
    return {
      mainHeight: "300px",
      results: [],
      listLoading: false,
      page: {},
      filters: {},
      selectedRows: [],

      addForm: {
        term: "",
        definition: "",
        status: "",
        grouping: "",
        createdAt: "",
      },
      addFormVisible: false,
      addLoading: false,

      editForm: {
        id: "",
        term: "",
        definition: "",
        status: "",
        grouping: "",
        createdAt: "",
      },
      editFormVisible: false,
      editLoading: false,
    };
  },
  mounted() {
    mainHeight(this);
    this.list();
  },
  methods: {
    list() {
      const me = this;
      me.listLoading = true;
      const params = Object.assign(me.filters, {
        page: me.page.page,
        limit: me.page.limit,
      });
      this.axios
        .form("/api/admin/dict/list", params)
        .then((data) => {
          me.results = data.results;
          me.page = data.page;
        })
        .finally(() => {
          me.listLoading = false;
        });
    },
    handlePageChange(val) {
      this.page.page = val;
      this.list();
    },
    handleLimitChange(val) {
      this.page.limit = val;
      this.list();
    },
    handleSelectionChange(val) {
      this.selectedRows = val;
    },
    handleAdd() {
      this.addForm = {
        name: "",
        description: "",
      };
      this.addFormVisible = true;
    },
    addSubmit() {
      const me = this;
      this.axios
        .form("/api/admin/dict/create", this.addForm)
        .then((data) => {
          me.$message({ message: "提交成功", type: "success" });
          me.addFormVisible = false;
          me.list();
        })
        .catch((rsp) => {
          me.$notify.error({ title: "错误", message: rsp.message });
        });
    },
    handleEdit(index, row) {
      const me = this;
      this.axios
        .get("/api/admin/dict/" + row.id)
        .then((data) => {
          me.editForm = Object.assign({}, data);
          me.editFormVisible = true;
        })
        .catch((rsp) => {
          me.$notify.error({ title: "错误", message: rsp.message });
        });
    },
    async handleDelete(index, row) {
      try {
        const flag = await this.$confirm("是否确认取消删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
        if (flag) {
          try {
            await this.axios.form("/api/admin/dict/delete", { id: row.id });
            this.list();
            this.$message.success("操作成功");
          } catch (err) {
            this.$notify.error({ title: "错误", message: err.message || err });
          }
        }
      } catch (e) {
        this.$message.success("操作已取消");
      }
    },
    editSubmit() {
      const me = this;
      this.axios
        .form("/api/admin/dict/update", me.editForm)
        .then((data) => {
          me.list();
          me.editFormVisible = false;
        })
        .catch((rsp) => {
          me.$notify.error({ title: "错误", message: rsp.message });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
